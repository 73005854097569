<template>
  <v-container pa-n1 >
     <v-card class="pa-4 ma-4" elevation="10">
     <h1 class="text-center">伊藤鉄工㈱　アクセス</h1>
     </v-card>
     <a href="/"><p>戻る</p></a>　
  <template>
   
       <v-btn large color="primary" class="buttonBig my-1" @click="$router.push({ path: 'osusume1' })">{{menu1}}</v-btn>
    
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.2401608157693!2d136.76542426560235!3d35.15063081649472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60039e9f9952cd07%3A0x7257d93b126cf62!2z5LyK6Jek6YmE5bel77yI5qCq77yJ!5e0!3m2!1sja!2sjp!4v1636299473303!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </template>
        
  </v-container>
</template>
<script>
import axios from 'axios'

 export default {
        components: {
    },
    data: () => ({
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 8,
      center: [35.17909, 136.72910],
    
        videoId: 'bzFmgzZKzGM',
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        items:[
            {
                title:"生産技術課ＣＡＤ端末構成図",
                text:"先端技術",
                img:"/img/CATIA.png"
                
            },
           
        ]
    }),
    mounted(){

  },
}
</script>
<style>
html, body, #app { height: 100% }
body { margin: 0 }

.marker {
    text-align      : center
;   color           : white
;   font-size       : 16
;   border-radius   : 8px
;   box-shadow      : 8px 8px 8px rgba( 0, 0, 0, 0.4 )
}
.red {
    background      : red
}
</style>