<template>
<div >
  <v-app style="background-color:#99CCFF" class=" ma-0 pa-0"  width="1326px" > 
        <v-divider></v-divider>
    <v-app-bar color="#99CCFF" style="background-color:#99CCFF" dark  clipped-left class="ma-0  pa-0"   width="1326px"  app>
    
      <v-toolbar-title class="AA">
      ようこそ        伊 藤 鉄 工 株 式 会 社
      </v-toolbar-title>
     <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn color="#0099FF" class="small bn" width="50px" height="50px" rounded to="/" >HOME</v-btn>     
        <v-btn color="#0099FF" class="small bn" width="50px" height="50px" rounded to="/Osusume3"  v-bind:class="{buttoncolor:buttonState8}" v-on:click="changeState8=!changeState8">スマホ<br>用</v-btn> 
         <v-btn  color="#0099FF"  class="small bn" width="50px" height="50px"  rounded to="/Sample"  v-bind:class="{buttoncolor:buttonState1}" v-on:click="changeState1=!changeState1" >企業<br>情報</v-btn>
         <v-btn color="#0099FF"  class="small bn" width="50px" height="50px" rounded to="/Sample1" v-bind:class="{buttoncolor:buttonState2}" v-on:click="changeState2=!changeState2">会社<br>方針</v-btn>
          <v-btn color="#0099FF" class="small bn" width="50px" height="50px" rounded to="/Sample2" v-bind:class="{buttoncolor:buttonState3}" v-on:click="changeState3=!changeState3">製品<br>情報</v-btn>
          <v-btn color="#0099FF" class="small bn" width="50px" height="50px" rounded to="/Sample3" v-bind:class="{buttoncolor:buttonState4}" v-on:click="changeState4=!changeState4">設備<br>情報</v-btn>
          <v-btn color="#0099FF" class="small bn" width="50px" height="50px" rounded to="/Sample4" v-bind:class="{buttoncolor:buttonState5}" v-on:click="changeState5=!changeState5">組織図</v-btn>
          <v-btn color="#0099FF" class="small bn" width="50px" height="50px"  rounded to="/Sample7"  v-bind:class="{buttoncolor:buttonState6}" v-on:click="changeState6=!changeState6">CATIA</v-btn>      
          <v-btn color="#0099FF" class="small bn" width="50px" height="50px"  rounded to="/Sample10"  v-bind:class="{buttoncolor:buttonState7}" v-on:click="changeState7=!changeState7">国際品質<br>規格</v-btn>
          <v-btn color="#0099FF" class="small bn" width="50px" height="50px" rounded to="/Osusume2"  v-bind:class="{buttoncolor:buttonState8}" v-on:click="changeState8=!changeState8">機械<br>作業</v-btn>        
          <v-btn color="#0099FF" class="small bn" width="50px" height="50px"   rounded to="/Sample8"  >アク<br>セス </v-btn>  
                      </v-toolbar-items>
    </v-app-bar>
    <v-main color="#0099FF" class="my-0 mx-0 pa-0 background-color:#D2B48C" ma-0  pa-0  height="1300px">
      <router-view />
    </v-main>
       <v-footer color="#0099FF" dark app class="ma-0  pa-0" >
      <div class="footer">
        © 伊藤鉄工株式会社 2021. All right reservedC
      </div> 
    </v-footer> 
    <!-- <script src="https://cdn.jsdelivr.net/npm/vue@2.6.14/dist/vue.js"></script>  -->
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet">
  </v-app>
  
</div>
</template>
<style lang="scss" scoped>
@import "./css/horizontal.css";
.footer {
  margin: auto;
}
.small{
   font-size:9px;
}
.bn{
  box-shadow: 0px 2px 2px 1px #dcdcdc;
  border-bottom: solid 5px #778899;
}
.buttoncolor {
    background-color: green;
}
.yellow{
  background-color: yellow;
}
.AA{
  color:black;
  size:large;
  font-weight:bold;
}
</style>
 
<script>


export default {

 
      data:()=>({
        url:'../',
        drawer: false,
        buttonState1:false,
        buttonState2:false,  
        buttonState3:false,
        buttonState4:false,
        buttonState5:false,
        buttonState6:false,
        buttonState7:false,
        buttonState8:false,
        changeState1:false,
        changeState2:false,
        changeState3:false,
        changeState4:false,
        changeState5:false,
        changeState6:false,
        changeState7:false,
        changeState8:false,
        nav_lists:[
          {
            name: '企業情報',
            icon: 'mdi-book-open',
            lists:[
              {
                name:'会社概要',link:'/Sample'
              },
              {
                name:'会社方針',link:'/Sample1'
              },
               {
                name:'製品情報',link:'/Sample2'
              },
               {
                name:'設備情報',link:'/Sample3'
              },
              {
                name:'組織図',link:'/Sample4'
              },
               {
                name:'郷土津島',link:'/Sample5'
              },
               {
                name:'本社アクセス',link:'/Sample8'
              },
               {
                name:'白浜アクセス',link:'/Sample9'
              },
            ]
          },
        
        ],
      
 
}),
  methods: {
          linkToOtherWindow (url) {
   
          window.open(this.url ,'_blank','noopener noreferrer' )
   
          },
          watch:{
      changeState1:function(newValue,oldValue){
        if(  newValue == true){
          this.buttonState1.color=yellow;
          this.buttonState2.color=blue;
          this.buttonState3.color=blue;
          this.buttonState4.color=blue;
          this.buttonState5.color=blue;
          this.buttonState6.color=blue;
          this.buttonState7.color=blue;
          this.buttonState8.color=blue;
          }
      },
      changeState2:function(newValue,oldValue){
         if( newValue == true){
          this.buttonState1.color=blue;
          this.buttonState2.color=yellow;
          this.buttonState3.color=blue;
          this.buttonState4.color=blue;
          this.buttonState5.color=blue;
          this.buttonState6.color=blue;
          this.buttonState7.color=blue;
          this.buttonState8.color=blue;
          }
      },
       changeState3:function(newValue,oldValue){
         if( newValue == true){
          this.buttonState1.color=blue;
          this.buttonState2.color=blue;
          this.buttonState3.color=yellow;
          this.buttonState4.color=blue;
          this.buttonState5.color=blue;
          this.buttonState6.color=blue;
          this.buttonState7.color=blue;
          this.buttonState8.color=blue;
          }
      },
       changeState4:function(newValue,oldValue){
          changeState4=newValue;
          if(newValue == true){
          this.buttonState1.color=blue;
          this.buttonState2.color=blue;
          this.buttonState3.color=blue;
          this.buttonState4.color=yellow;
          this.buttonState5.color=blue;
          this.buttonState6.color=blue;
          this.buttonState7.color=blue;
          this.buttonState8.color=blue;
          }
      },
      changeState5:function(newValue,oldValue){
         if(newValue == true){
          this.buttonState1.color=blue;
          this.buttonState2.color=blue;
          this.buttonState3.color=blue;
          this.buttonState4.color=blue;
          this.buttonState5.color=yellow;
          this.buttonState6.color=blue;
          this.buttonState7.color=blue;
          this.buttonState8.color=blue;
         }
      },
      changeState6:function(newValue,oldValue){
          if(newValue == true){
          this.buttonState1.color=blue;
          this.buttonState2.color=blue;
          this.buttonState3.color=blue;
          this.buttonState4.color=blue;
          this.buttonState5.color=blue;
          this.buttonState6.color=yellow;
          this.buttonState7.color=blue;
          this.buttonState8.color=blue;
          }
      },
      changeState7:function(newValue,oldValue){
          if(newValue == true){
          this.buttonState1.color=blue;
          this.buttonState2.color=blue;
          this.buttonState3.color=blue;
          this.buttonState4.color=blue;
          this.buttonState5.color=blue;
          this.buttonState6.color=blue;
          this.buttonState7.color=yellow;
          this.buttonState8.color=blue;
          }
      },
      changeState8:function(newValue,oldValue){
          if(newValue == true){
          this.buttonState1.color=blue;
          this.buttonState2.color=blue;
          this.buttonState3.color=blue;
          this.buttonState4.color=blue;
          this.buttonState5.color=blue;
          this.buttonState6.color=blue;
          this.buttonState7.color=blue;
          this.buttonState8.color=yellow;
          }
      }
   },

}
}

</script>