<template >
  <v-container style="background-color:#99CCFF  height=300%" >
      <v-row class="my-2 py-2  solid #f2f2f2" cols="12">
          <v-col class="my-2 py-2   solid #f2f2f2" cols="10">
  <v-card class="pa-n2 ma-n2" elevation="10">
    <h1 class="text-center ma-3 pa-3">会社概要<h5>伊藤鉄工㈱</h5></h1>
  </v-card>  
  　　<a href="/"><p>戻る</p></a>　
            <b-table  v-if="ok" thead-class="d-none" class="table padding-right: 15em; table-bordered border-primary   border:2px ;border-collapse:collapse;background:#ccc bg-white font-weight:c4bold"   :fields ="yfields"  :items="sampleData" responsive="md"  />                 
            <v-spacer />
            <v-spacer />
  　　　　　　　　　</v-col>  
       </v-row>      
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
    components: {
    },
    data: () => ({
        ok:true,
        text1:'',
        data1:'問い合わせ',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        sampleData:[
            {
                項目:"会社名",
                内容:"■ 伊 藤 鉄 工 株 式 会 社",
                 _cellVariants: { 項目: 'info'}
            },
              {
                項目:"代表取締役",
                内容:"■ 伊 藤 彰 彦",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"所在地",
                内容:"■ 愛知県津島市藤浪町１丁目３９番地",
                 _cellVariants: { 項目: 'info'}
            },
            
            {
                項目:"創　立",
                内容:"■ 大正14年1月8日（1925年）",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"設　立",
                内容:"■ 昭和24年1月8日（1949年）",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"資本金",
                内容:"■ １，０００万円",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"事業内容",
                内容:"■ 航空宇宙部品・飛翔体・エンジンなどの精密機械部品",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:"        ",
                内容:"■ アルミ、チタン、インコネル、CFRP等の難削材加工",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"所在地　本社工場",
                内容:"■ 〒 496-0801　愛知県津島市藤浪町1丁目39番地 　　　Tel：0567-26-2187　　Fax：0567-26-7100",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　　白浜工場",
                内容:"■ 〒 496-0016　愛知県津島市白浜町字下池57番地1　　Tel：0567-32-3677　　Fax：0567-32-3678",
                 _cellVariants: { 項目: 'info'}
            },
           
        ],
        
    }),
       yfields: [
        { key: 'index', label: ' ', thStyle: {background: 'info', color: '#ffffff'} },
        { key: '項目', label: '', thStyle: {background: 'info', color: '#ffffff'} },
        { key: '内容', label: '', thClass: 'd-none', tdClass: 'd-none',
          thStyle: {background: 'info', color: '#ffffff'} },
            ],
    methods:{
        search:function() {
        }
    }
}
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 5px solid #f2f2f2;
}
.c1-3bold{
    font-weight:bold;
}
.f{
    font-size:12px;
}
</style>