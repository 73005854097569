<template>
<v-container>
<v-card class="pa-4 ma-4" elevation="10">
    <h1 class="text-center">伊藤鉄工㈱  組織図</h1>
</v-card>
<a href="/"><p>戻る</p></a>　
   
      <v-row class="home-about__contents" cols="12">
        <v-col md="8" class="home-about__contents-img bg">
          <img src="/img/伊藤鉄工組織図.png" alt="" class="img-fluid">
        </v-col>
      
      </v-row>
    
  </v-container>
   
</template> 

<script>
import axios from 'axios'
export default {
    components: {
    },
    data: () => ({
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        sampleData:[
            {
                name:"横型５軸マシニングセンター    ",
                calories:"2000×2550×1400",
                su:"4台"
            },
            {
                name:"横型５軸マシニングセンター",
                calories:"1060×1050×1050",
                su:"２台"
            },
            {
                name:"門型５軸マシニングセンター",
                calories:"1400×4200× 610",
                su:"4台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"1550×1000×1000",
                su:"２台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"1000× 800× 800",
                su:"１台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"900 × 800×1020",
                su:"１台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"800 × 650× 650",
                su:"７台"
            },
            {
                name:"横型３軸マシニングセンター",
                calories:"12000×2500×700",
                su:"１台"
            },
            {
                name:"門型３軸マシニングセンター",
                calories:"4000×2700× 800",
                su:"１台"
            },
            {
                name:"門型３軸マシニングセンター",
                calories:"4200×3000× 1500",
                su:"１台"
            },
            {
                name:"門型３軸マシニングセンター",
                calories:"3000×1600× 450",
                su:"１台"
            },
             {
                name:"竪型３軸マシニングセンター",
                calories:"1050× 560× 560他",
                su:"１１台"
            },
            {
                name:"NC竪旋盤",
                calories:"1450× 900",
                su:"２台"
            },
             {
                name:"NC旋盤",
                calories:"495×2070  他",
                su:"６台"
            },
            {
                name:"ワイヤーカット",
                calories:"300× 450×(260)",
                su:"１台"
            },
            {
                name:"汎用フライス、汎用レース、ボール盤",
                calories:" ",
                su:"３５台"
            },
             {
                name:"三次元測定機",
                calories:" ",
                su:"１台"
            },
        ]
    }),
    methods:{
        search:function() {
            const params = {
                method_name:'YOTEI_HinmokuSD', 
                params:{   
                    strHinmoku: 'B',
                    FLG:'false'
                }
            };
            axios.post('http://localhost/toyo/public/api/soap-call', params)
            .then(response => {
                this.sampleData = response.data.SQL_YOTEI;
                
            })
        }
    }
}
</script>
<style lang="scss" scoped>
/* ここから追加 */
.home-about__contents {
  margin-top: 20px;
  justify-content: center;
 
  &-img {
    text-align: center;
  }
 
  &-text {
    word-wrap: break-word;
  }
}
/* ここまで追加 */
</style>