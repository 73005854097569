<template>

  <v-container pa-n1 >
     <v-card class="pa-4 ma-4" elevation="10">
     <h1 class="text-center">伊藤鉄工㈱　アクセス</h1>
     </v-card>
     <a href="/"><p>戻る</p></a>　
  <template>
    
      <v-row cols="12">
        <v-col cols="5" >
      <!-- <template> -->
             <v-btn  large color="primary" class="buttonBig my-1" @click="$router.push({ path: 'osusume1' })">本社</v-btn>
        
         <div class="d-flex justify-content-center">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1152.9815036684877!2d136.72978010006813!3d35.17845786882281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600398c2702a8671%3A0x1911242eba4c3f4e!2z5LyK6Jek6YmE5bel!5e0!3m2!1sja!2sjp!4v1637326658376!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>                 
          </div>
         </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="5">    
                <v-btn   large color="primary" class="buttonBig my-1" @click="$router.push({ path: 'osusume1' })">白浜</v-btn>  
                <div class="d-flex justify-content-center">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.2401608157693!2d136.76542426560235!3d35.15063081649472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60039e9f9952cd07%3A0x7257d93b126cf62!2z5LyK6Jek6YmE5bel77yI5qCq77yJ!5e0!3m2!1sja!2sjp!4v1636299473303!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
       </v-col>
        </v-row>
    </template>
    <v-row cols="12">
         <v-col cols="8">
        <b-table  v-if="ok" thead-class="d-none" class="table padding-right: 15em; table-bordered border-primary   border:2px ;border-collapse:collapse;background:#ccc bg-white font-weight:c4bold"   :fields ="yfields"  :items="sampleData" responsive="md"  />                 
　　　　　　  </v-col>
         <v-col cols="2">
             <a href="mailto:info@ito1925.co.jp?subject=問い合わせ&amp;body=問い合わせの件"><p>問い合わせメール</p></a>　
       </v-col>
        </v-row>
  </v-container>
 
</template>

<script>
import axios from 'axios'
import  'leaflet/dist/leaflet.css'
import  L from 'leaflet'
import icon from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

 export default {
        components: {
    },
    
    data: () => ({
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 8,
      center: [35.17909, 136.72910],
        ok:true,
        videoId: 'bzFmgzZKzGM',
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        items:[
            {
                title:"生産技術課ＣＡＤ端末構成図",
                text:"先端技術",
                img:"/img/CATIA.png"
                
            },
            
        ],
         sampleData:[
            {
                項目:"伊藤鉄工問い合わせ",
                内容:"営業連絡先",
                 _cellVariants: { 項目: 'info'}
            },
            
            {
                項目:"■ 〒 496-0801　愛知県津島市藤浪町1丁目39番地       Tel：0567-26-2187     Fax：0567-26-7100",
                内容:"",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"■ 〒 496-0016　愛知県津島市白浜町字下池57番地1      Tel：0567-32-3677     Fax：0567-32-3678",
                内容:"",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"■担当　伊藤　■ＴＥＬ：0567-26-2187 ",
                内容:"",
                 _cellVariants: { 項目: 'info'}
            },
        ],
         yfields: [
        { key: 'index', label: ' ', thStyle: {background: 'info', color: '#ffffff'} },
        { key: '項目', label: '', thStyle: {background: 'info', color: '#ffffff'} },
        { key: '内容', label: '', thClass: 'd-none', tdClass: 'd-none',
          thStyle: {background: 'info', color: '#ffffff'} },
            ],
    }),
    mounted(){

  },
  methods:{
     close: function() {
      this.expand = false
    },
    onRightClick: function(e){
        this.close();
    }
  }
}
</script>
<style>
html, body, #app { height: 100% }
body { margin: 0 }

.marker {
    text-align      : center
;   color           : white
;   font-size       : 16
;   border-radius   : 8px
;   box-shadow      : 8px 8px 8px rgba( 0, 0, 0, 0.4 )
}
.red {
    background      : red
}
.folium-map{
   height: 50px; width: 50px
}
</style>