<template>
  <v-container>
      <v-card class="pa-4 ma-4" elevation="10">
    <h1 class="text-center">会社方針<h5>伊藤鉄工㈱</h5>  </h1>
    
      </v-card>
      <a href="/"><p>戻る</p></a>　
        <transition name="bounce">
  <v-row dense style="" class=""  align="center" justify="center">
      
      <b-col md="5" cols="6" class="sticky_table_wrapper" />
                 <b-table  v-if="ok" thead-class="d-none" class="table padding-right: 15em; table-bordered border-primary   border:2px ;border-collapse:collapse;background:#ccc bg-white font-weight:c4bold"   :fields ="yfields"  :items="sampleData" responsive="md"  />                 
            </v-row>  
       </transition >      
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
    components: {
    },
    data: () => ({
        ok:true,
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        sampleData:[
             {
                項目:"社長 方針",
                内容:" 優れた部品供給の精神をもって",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　   ",
                内容:" お取引先との共存・共栄を図り",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　   ",
                内容:" 社会に貢献し信頼される企業を目指します。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　   ",
                内容:"  ",
                 _cellVariants: { 項目: 'info',内容:'info'}
            },
             {
                項目:"　   ",
                内容:"  ",
                 _cellVariants: { 項目: 'info',内容:'info'}
            },
            {
                項目:"会社 方針",
                内容:"弊社は、大正14年に創立以来、鉄道車両・紡織機などの部品の歩み",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　   ",
                内容:"設計・製造・修理を幅広く手掛け、技術の蓄積に努めてまいりました。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　   ",
                内容:"戦後（昭和31年）、航空機生産の再開を機に、いち早く",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　   ",
                内容:" 新三菱重工業株式会社名古屋航空機製作所＜現在の三菱重工業㈱名古屋航空宇宙システム製作所＞の協力会社となり、",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　   ",
                内容:" 航空機部品および治工具の製造に参画しました。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　  ",
                内容:"以来、航空・宇宙産業の発展と共に堅実な歩みを続け、今日に至っております。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　  ",
                内容:"航空・宇宙産業は、知識集約型産業として日本の将来を担う極めて有望な産業になりつつあります。",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:"　  ",
                内容:"当社は、今後ますます技術的に高度化するこの業界のニーズに応えるべく、",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:"　  ",
                内容:"●この業界の一員たることに誇りをもって",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:"　  ",
                内容:"●人材の育成と社員の福祉向上を基本に",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:"　  ",
                内容:"●先端技術の吸収・開発と設備の近代化に",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:"　  ",
                内容:"限りない精進を重ねていく所存であります。",
                 _cellVariants: { 項目: 'info'}
            },
        ]
    }),
      yfields: [
        { key: 'index', label: ' ', thStyle: {background: 'info', color: '#ffffff'} },
        { key: '項目', label: '', thStyle: {background: 'info', color: '#ffffff'} },
        { key: '内容', label: '', thClass: 'd-none', tdClass: 'd-none',
          thStyle: {background: 'info', color: '#ffffff'} },
            ],
    methods:{
        search:function() {
            const params = {
                method_name:'YOTEI_HinmokuSD', 
                params:{    
                    strHinmoku: 'B',
                    FLG:'false'
                }
            };
            axios.post('http://localhost/toyo/public/api/soap-call', params)
            .then(response => {
                this.sampleData = response.data.SQL_YOTEI;

            })
        }
    }
}


</script>

