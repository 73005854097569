<template>
  <v-container>
    <h4 class="text-center">処理内容を選択して下さい</h4>
    <validation-observer>
      <form>
        <v-row dense>
          <v-spacer></v-spacer>
            <v-col xs6 sm6 md6 >
              <v-btn large color="primary" class="buttonBig my-1" @click="$router.push({ path: 'osusume1' })">{{menu1}}</v-btn>
            </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row dense>
          <v-spacer></v-spacer>
            <v-col xs6 sm6 md6 >
              <v-btn large color="primary" class="buttonBig my-1" @click="$router.push({ path: 'osusume2' })">{{menu2}}</v-btn>
            </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row dense>
          <v-spacer></v-spacer>
            <v-col xs6 sm6 md6 >
              <v-btn large color="primary" class="buttonBig my-1" @click="$router.push({ path: 'yoyaku' })">{{menu3}}</v-btn>
            </v-col>
          <v-spacer></v-spacer>
        </v-row>
       </form>  
    </validation-observer>
  </v-container>
</template>
<style lang="scss" scoped>
.buttonBig{
  font-weight:bolder;
  color:white;
  font-size:xx-large;
}
</style>
<script>
var sound_OK;
var sound_NG;
export default {
  data () {
    return {
      messge:'',
      menu1:'　本日のおすすめ１　',
      menu2:'　本日のおすすめ２　',
      menu3:'　　　予　　約　　　',
    }
  },
  created: function() {
    this.$parent.title = 'メインメニュー';
    this.$emit('setTitle');
    sound_OK = new Audio("data:audio/wav;base64," + this.$router.options.base64_OK);
    sound_NG = new Audio("data:audio/wav;base64," + this.$router.options.base64_NG);
  },
}
</script>