<template>
  <v-container>
    <h1 class="text-center">アクセス 本社 |<h5>伊藤鉄工㈱</h5></h1>
   
    <v-simple-table dense>
        <template v-slot:default>
         <v-card >
            <v-card-title class="headline cyan lighten-2 large">
             {{title}} 伊藤鉄工株式会社 位置
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>
          <a href="/"><p>戻る</p></a>　         
        </template>     
    </v-simple-table>
  </v-container>
</template>
<script>
import axios from 'axios'

 export default {
     name: "sample6",
    data: () => ({
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 8,
      center: [35.17909, 136.72910],
    
        videoId: 'bzFmgzZKzGM',
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        sampleData:[
            {
                name:"横型５軸マシニングセンター  ",
                calories:"2000×2550×1400",
                su:"4台"
            },
            {
                name:"横型５軸マシニングセンター",
                calories:"1060×1050×1050",
                su:"２台"
            },
            {
                name:"門型５軸マシニングセンター",
                calories:"1400×4200× 610",
                su:"4台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"1550×1000×1000",
                su:"２台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"1000× 800× 800",
                su:"１台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"900 × 800×1020",
                su:"１台"
            },
            {
                name:"横型４軸マシニングセンター",
                calories:"800 × 650× 650",
                su:"７台"
            },
            {
                name:"横型３軸マシニングセンター",
                calories:"12000×2500×700",
                su:"１台"
            },
            {
                name:"門型３軸マシニングセンター",
                calories:"4000×2700× 800",
                su:"１台"
            },
            {
                name:"門型３軸マシニングセンター",
                calories:"4200×3000× 1500",
                su:"１台"
            },
            {
                name:"門型３軸マシニングセンター",
                calories:"3000×1600× 450",
                su:"１台"
            },
             {
                name:"竪型３軸マシニングセンター",
                calories:"1050× 560× 560他",
                su:"１１台"
            },
            {
                name:"NC竪旋盤",
                calories:"1450× 900",
                su:"２台"
            },
             {
                name:"NC旋盤",
                calories:"495×2070 他",
                su:"６台"
            },
            {
                name:"ワイヤーカット",
                calories:"300× 450×(260)",
                su:"１台"
            },
            {
                name:"汎用フライス、汎用レース、ボール盤",
                calories:" ",
                su:"３５台"
            },
             {
                name:"三次元測定機",
                calories:" ",
                su:"１台"
            },
        ]
    }),
    mounted(){

  },
}
  
</script>
<style>
html, body, #app { height: 100% }
body { margin: 0 }

.marker {
    text-align      : center
;   color           : white
;   font-size       : 16
;   border-radius   : 8px
;   box-shadow      : 8px 8px 8px rgba( 0, 0, 0, 0.4 )
}
.red {
    background      : red
}
</style>