<template>
  <v-container width="100vw" height="100vh">
    <v-card class="pa-4 ma-4" elevation="10">
    <h1 class="text-center">■■  機械  ■■  CATIA  ■■ <h5>伊藤鉄工㈱</h5></h1>
    </v-card>
   <a href="/"><p>戻る</p></a>　
    <validation-observer>    
        <form>
            <v-row dense>
                <b-table small sticky-header :fields="nfields"  :items="nitems" responsive="sm" >
                    <template #cell(index)="data" id="hh" >
                        {{ data.index + 1 }}
                    </template>
                    <template #cell()="data">
                      <i>{{ data.value }}</i>
                    </template>
                </b-table>
                <b-table small sticky-header :fields="nfields"  :items="d1items" responsive="sm" >
                    <template #cell(index)="data" id="hh" >
                        {{ data.index + 1 }}
                    </template>
                    <template #cell()="data">
                      <i>{{ data.value }}</i>
                    </template>
                </b-table>
                <b-table small sticky-header :fields="nfields"  :items="d2items" responsive="sm" >
                    <template #cell(index)="data" id="hh" >
                        {{ data.index + 1 }}
                    </template>
                    <template #cell()="data">
                      <i>{{ data.value }}</i>
                    </template>
                </b-table>
            </v-row>
        </form>  
        <v-dialog
          v-model="dialog"
          hide-overlay
          persistent
          no-click-animation
          width="300"
        >
          <v-card color="primary" dark>
            <v-card-text>
              通信中
              <v-progress-linear indeterminate color="white" class="mb-0" >
              </v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogYes"
          persistent
          no-click-animation
          width="450"
        >
          <v-card>
            <v-card-title class="headline cyan lighten-2">
              選択画像
            </v-card-title>
            <v-card-text v-html="messge">
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary" class="white--text my-2"
                @click="dialogYes = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </validation-observer>
     <v-card class="pa-4 ma-4" elevation="10">
    <h1 class="text-center"><h2>機械</h2></h1>
    </v-card>
<div id="app">
  <ul class="category-list">
    <li v-for="row in d1items" :key="row.index">
      <img :src="row.FLG" alt="" pt-2 pb-2>
    
       <h5 class="d-flex justify-content-center">{{row.品名}} </h5> 
    </li>
  </ul>
</div>
 <v-card class="pa-4 ma-4" elevation="10">
    <v-spacer />
<h1 class="text-center" pt-2><h2>作業</h2></h1>
 </v-card>
<div id="app">
  <ul class="category-list">
    <li v-for="row in d2items" :key="row.index">
      <img :src="row.FLG" alt="" pt-2 pb-2>
      
        <h5 class="d-flex justify-content-center">{{row.品名}} </h5> 
    </li>
  </ul>
</div>
  </v-container>
</template>
 
<script>
import axios from 'axios'
import { Slider, SliderItem } from 'vue-easy-slider';
var sound_OK;
var sound_NG;
var url_kannami;
export default {
  data () {
    return {
      dialog: false,
      dialogYes: false,
      messge:'',
      preIndex1: 0,
      preIndex2: 0,
      params:[],

      nfields: [
          {
              key: 'index',
              label: ' ',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '品名CODE',
              label: '品名CODE',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '品名',
              label: '品名',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '売上単価',
              label: '売上単価',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: 'フィールド1',
              label: 'フィールド1',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: 'FLG',
              label: 'FLG',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
      ],
      nitems:[
         {
         フィールド1:"2002",
         品名:"CATIA室",
         品名CODE:"2002",
        },
         {
         フィールド1:"2003",
         品名:"CATIA室",
         品名CODE:"2003",
        },
       {
         フィールド1:"2004",
         品名:"CATIA室",
         品名CODE:"2004",
        },
         {
         フィールド1:"2005",
         品名:"検査室",
         品名CODE:"2005",
        },
         {
         フィールド1:"2006",
         品名:"検査室",
         品名CODE:"2006",
        },
          {
         フィールド1:"6001",
         品名:"MAG-3",
         品名CODE:"6001",
        },
           {
         フィールド1:"6002",
         品名:"5軸FMS",
         品名CODE:"6002",
        },
          {
         フィールド1:"6003",
         品名:"VORTEX1400",
         品名CODE:"6003",
        },
         {
         フィールド1:"6004",
         品名:"INTEGREX I-300",
         品名CODE:"6004",
        },
        {
         フィールド1:"6005",
         品名:"MH-60-5S",
         品名CODE:"6005",
        },
         {
         フィールド1:"6006",
         品名:"VARIAXIS 630-5X",
         品名CODE:"6006",
        },
      ],
      selectedt:[],
      d1items:[],
      d2items:[],
    }
  },
  components: {
    Slider,
    SliderItem,
  },
  created: function() {
    this.$parent.title = 'サブメニュー１';
    this.$emit('setTitle');
    sound_OK = new Audio("data:audio/wav;base64," + this.$router.options.base64_OK);
    sound_NG = new Audio("data:audio/wav;base64," + this.$router.options.base64_NG);
    url_kannami = this.$router.options.g_kannami;
            this.checkItem();
  },
  methods:{
    checkItem() {
        var len;
        this.d1items = [];
        this.d2items = [];
        this.selectedt = this.nitems;

        for (var i=0; len=this.selectedt.length; i<len) {
            if (i == len) {break;}
            if(this.selectedt[i].フィールド1 > 5999) {
                this.selectedt[i].FLG = "../img/" + this.selectedt[i].品名CODE + ".jpg"
                this.d1items.push(this.selectedt[i]);
            } else if(this.selectedt[i].フィールド1 > 1999) {
                this.selectedt[i].FLG = "../img/" + this.selectedt[i].品名CODE + ".jpg"
                this.d2items.push(this.selectedt[i]);
            }
            i++;
        }
    },
    onPageChange1: function(index) {
      this.preIndex1 = index;
    },
    onPageChange2: function(index) {
      this.preIndex2 = index;
    },
    imgclick1() {
      this.messge = "<br/>画像ファイルがクリックされました。ID=" + this.preIndex1 + "<br/><br/>商品：" + this.d1items[this.preIndex1].品名;
      this.dialogYes = true;
    },
    imgclick2() {
      this.messge = "<br/>画像ファイルがクリックされました。ID=" + this.preIndex2 + "<br/><br/>商品：" + this.d2items[this.preIndex2].品名;
      this.dialogYes = true;
    },

  }
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.slide-item {
  height: 600px;
 
  background-color: #3FB811; 
  display: flex;
  margin:  0 auto; 
  justify-content: center; 
  align-items: center; 
  color: #fff;  
  font-size: 34px; 
  border-radius: 20px;
}
.VueCarousel-slide {
  font-size:12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  background:#ccc;
  border-right:1px solid #FFF;
  box-sizing:border-box;
  transform: scale(0.6, 0.6);
}
.imgscale {
  height: 300px;
  transform: scale(0.9, 0.9);
}

.VueCarousel-navigation-next {
  font-size: 4rem;
}

.VueCarousel-navigation-prev {
  font-size: 4rem;
}

.VueCarousel-navigation--disabled {
  display: none;
}
.category-list {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
.category-list img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}
</style>
 