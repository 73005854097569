<template>
  <v-container pa-n1 >
    <v-card class="pa-0 ma-0" elevation="10">
     <h1 class="text-center ma-n0 pa-n0">生産技術課 CATIA システム<h5>伊藤鉄工㈱</h5></h1>
    </v-card>
    <a href="/" class="pa-0 ma-0 pt-n2 pb-n2"><p>戻る</p></a>　
     <v-card class="pa-0 ma-0 pt-n2 pb-n2" elevation="10">
    <h1 class="text-center pa-0 ma-0 pt-n2 pb-n2"><h2>ベリカット加工検証1 ・ ベリカット加工検証2</h2></h1>
    </v-card>

   <v-row class="home-about__contents pa-0 ma-0" cols="12">
     <v-col cols="2"></v-col>
      <v-col lg="7" class="home-about__contents-img md justify-content-center pa-0 ma-0 mx-0">
      <img src="/img/ベリカットにて加工検証1.jpg" alt="" class="img-fluid d-flex justify-content-center" width="100%">  
       </v-col>
       <!-- <h5 class="d-flex justify-content-center">ベリカットにて加工検証1</h5>  -->
      <v-col cols="3">
      </v-col>
   </v-row>
 <!-- <v-card class="pa-1 ma-1" elevation="6">
    <h1 class="text-center"><h2>ベリカット加工検証2</h2></h1>
    </v-card> -->
    
   <!-- <v-row class="home-about__contents" cols="6"> -->
     <v-row class="home-about__contents pa-0 ma-0" cols="12">
       <v-col cols="2"></v-col>
      <v-col lg="7" class="home-about__contents-img md justify-content-center pa-1 ma-0 mx-0">
      <img src="/img/ベリカットにて加工検証2.jpg" alt="" class="img-fluid d-flex justify-content-center" width="100%">  
       <!-- <h5 class="d-flex justify-content-center">ベリカットにて加工検証2</h5>  -->
      </v-col>
       <v-col cols="3">
      </v-col>
  </v-row>

 
  </v-container>
</template>
<script>
import axios from 'axios'

 export default {
        components: {
    },
    data: () => ({
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 8,
      center: [35.17909, 136.72910],
    
        videoId: 'bzFmgzZKzGM',
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        items:[
            
            {
                title:"CATA設計ルーム",
                text:"CATIA端末操作",
                img:"/img/画像3.jpg"
            },
            {
                 title:"CATA設計ルーム",
                text:"CATIA端末操作",
                img:"/img/画像4.jpg"
            },
           
           
        ]
    }),
    mounted(){

  },
}
   
</script>
<style>
html, body, #app { height: 100% }
body { margin: 0 }

.marker {
    text-align      : center
;   color           : white
;   font-size       : 16
;   border-radius   : 8px
;   box-shadow      : 8px 8px 8px rgba( 0, 0, 0, 0.4 )
}
.red {
    background      : red
}
</style>