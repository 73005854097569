import Vue from 'vue'
import App from './App.vue'
import VueCarousel from 'vue-carousel';
import VueEasySlider from 'vue-easy-slider'
import vuetify from './plugins/vuetify';
import router from './router'
import { required, min, length, integer } from 'vee-validate/dist/rules'; 
import ja from 'vee-validate/dist/locale/ja.json';
import axios from 'axios'
import { BootstrapVue,IconsPlugin}  from 'bootstrap-vue' 
import 'bootstrap/dist/css/bootstrap.css' 
import 'bootstrap-vue/dist/bootstrap-vue.css' 
Vue.component('axios', axios )
Vue.use(VueCarousel);
Vue.use(VueEasySlider );
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
localize('ja', ja);
extend('required', {
  ...required,
})
extend('integer', {
  ...integer,
})
extend('length', {
  ...length,
})
extend('min', {
  ...min,
})

