<template >
  <v-container width="100vw" height="100vh">
      <v-card class="pa-4 ma-4" elevation="10">
    <h1 class="text-center" >設備情報<h5>伊藤鉄工㈱</h5></h1>
      </v-card>
      <a href="/"><p>戻る</p></a>　
    
      <transition name="bounce">
  <v-row dense style="background-color:#99CCFF"  align="center" justify="center" class="my-n2 mx-n2 py-n2 px-n2 ">
      
      <b-col md="5" cols="6" style="background-color:#99CCFF" />
           <v-card class="pa-4 ma-4">
               <b-table v-if="ok"  thead-class="d-none"   striped hover  style="background-color:white" class="table table-bordered border-primary " :fields="yfields"  :items="sampleData" responsive="sm" height="50vh">
                  <template #cell(index)="data" id="hh" >
                      {{ data.index + 1 }}
                  </template>
                  <template #cell()="data">
                    <i>{{ data.value }}</i>
                  </template>
              </b-table>
           </v-card>
    </v-row>
   
       </transition >      
  </v-container>
 
</template>
<script>
import axios from 'axios'
export default {
    components: {
    },
    data: () => ({
        ok:true,
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        sampleData:[
            {
                NO:"1",
                設備名称:"横型５軸マシニングセンター　　",
                型式:"MAG3",
                仕様最大能力:"3,000×1,500×1,000",
                回転数:"33,000",
                台数:"1台",
                備考:"2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"2",
                設備名称:"竪型５軸マシニングセンター",
                 型式:"VORTEX i-630V/6",
                仕様最大能力:"1,425×1,050×1,050",
                回転数:"10,000",
                台数:"3台",
                備考:"FMS3台使用　高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"3",
                設備名称:"門型５軸マシニングセンター",
                 型式:"VMP-16",
                仕様最大能力:"2,000×2,550×1,400",
                回転数:"6,000",
                台数:"1台",
                備考:"2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"4",
                設備名称:"横型5軸マシニングセンター",
                 型式:"MH-60-5S",
                仕様最大能力:"1,060×1,050×1,050",
                回転数:"10,000",
                台数:"1台",
                備考:"2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"5",
                設備名称:"門型５軸マシニングセンター",
               型式:"VORTEX 1400他",
                仕様最大能力:"4,200×1,400×610",
                回転数:"13,000",
                台数:"2台",
                備考:"2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"6",
                設備名称:"竪型５軸マシニングセンター",
               型式:"VARIAXIS 630-5XⅡ",
                仕様最大能力:" 630×765×610",
                回転数:"12,000",
                台数:"1台",
                備考:"2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"7",
                設備名称:"竪型５軸マシニングセンター",
               型式:"VORTEX i-630V/6",
                仕様最大能力:" 1,425×1,050×1,050",
                回転数:"10,000",
                台数:"1台",
                備考:"2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"8",
                設備名称:"横型４軸マシニングセンター",
                型式:"MH-100他",
                仕様最大能力:"1,550×1,350×1,000",
                回転数:"12,000",
                台数:"2台",
                備考:"1ｍ　2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
               {
                   NO:"9",
                設備名称:"横型４軸マシニングセンター",
                型式:"a81他",
                仕様最大能力:"720×700×650",
                回転数:"12,000",
                台数:"4台",
                備考:"2APC 高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"10",
                設備名称:"門型３軸マシニングセンター",
                型式:"MCR-20BⅡ",
                仕様最大能力:"4,000×2,700×800",
                回転数:"4,000",
                台数:"1台",
                備考:"３ｍ横桁移動 700",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"11",
                設備名称:"門型5面３軸マシニングセンター",
                型式:"MVR-30",
                 仕様最大能力:"4,200×3,000×1,500",
                回転数:"6,000",
                台数:"1台",
                備考:"３ｍ横桁移動 700",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"12",
                設備名称:"門型３軸マシニングセンター",
                型式:"MCV-16AⅡ",
                 仕様最大能力:"3,000×1,600×450",
                回転数:"4,000",
                台数:"1台",
                備考:"3ｍ横桁移動 1,000",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"13",
                設備名称:"竪型３軸マシニングセンター",
                型式:"MCV-560他",
                 仕様最大能力:"1,050×560×535",
                回転数:"6,000",
                台数:"5台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"14",
                設備名称:"竪型３軸マシニングセンター",
                型式:"M-V60B",
                 仕様最大能力:"1,020×510×550",
                回転数:"4,000",
                台数:"1台",
                備考:"複合材専用機",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"15",
                設備名称:"竪型中型３軸マシニングセンター",
                型式:"MCV-410他",
                 仕様最大能力:"760×410×485",
                回転数:"3,500",
                台数:"5台",
                備考:"高速輪郭制御",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"16",
                設備名称:"同時５軸複合旋盤",
                型式:"INTEGREX　I-300",
                仕様最大能力:"615×260x1,585",
                回転数:"10,000",
                台数:"1台",
                備考:"ミル主軸",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"17",
                設備名称:"NC竪旋盤",
                型式:"TM2-12N",
                 仕様最大能力:"1,025×850",
                回転数:"350",
                台数:"1台",
                備考:"1.6ｍ振り回し2軸制御（X,Y）",
                 _cellVariants: { NO: 'info'}
            },
            {
                NO:"18",
                設備名称:"NC旋盤",
                型式:"SLANT TURN-40N",
                 仕様最大能力:"495×2,070",
                回転数:"2,200",
                台数:"1台",
                備考:"芯間2ｍ",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"19",
                設備名称:"NC旋盤",
                型式:"M-4他",
                 仕様最大能力:"365×1020",
                回転数:"2,200",
                台数:"3台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
            {
                 NO:"20",
                設備名称:"竪型フライス盤",
                型式:"MH2V他",
                 仕様最大能力:"700×300×360",
                回転数:"1,800",
                台数:"3台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"21",
                設備名称:"万能フライス盤",
                型式:"KGJP-55他",
                 仕様最大能力:"500×300×350",
                回転数:"2,200",
                台数:"2台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"22",
                設備名称:"横フライス",
                型式:"HF2他",
                 仕様最大能力:"550×300×400",
                回転数:"1,760",
                台数:"2台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"23",
                設備名称:"NC横フライス",
                型式:"RRM3P",
                 仕様最大能力:"920×380×450",
                回転数:"1,600",
                台数:"1台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
            {
                 NO:"24",
                設備名称:"普通旋盤",
                型式:"LS",
                 仕様最大能力:"φ500×700",
                回転数:"1,800",
                台数:"3台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
            {
                 NO:"25",
                設備名称:"ラジアルボール盤",
                型式:"DRA-J他",
                 仕様最大能力:"軸移動量　1,000",
                回転数:"2,240",
                台数:"3台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
            {
                 NO:"26",
                設備名称:"アプライト ドリル",
                型式:"AFD410他",
                 仕様最大能力:　"軸移動量　125",
                回転数:"1,870",
                台数:"9台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"27",
                設備名称:"ジグボーラ",
                型式:"YZ-500WR他",
                 仕様最大能力:　"1,050×550×720",
                回転数:"2,000",
                台数:"2台",
                備考:"2軸制御（X,Y）",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"28",
                設備名称:"材料切断機",
                型式:"HA-250他",
                 仕様最大能力:　"250×300(φ250)",
                回転数:"",
                台数:"3台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
             {
                 NO:"29",
                設備名称:"ワイヤーカット",
                型式:"DWC-110H",
                 仕様最大能力:　"300×450×(260)",
                回転数:"",
                台数:"1台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
              {
                 NO:"30",
                設備名称:"三次元測定機",
                型式:"KN807",
                 仕様最大能力:　"850×700×600",
                回転数:"",
                台数:"1台",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
               {
                 NO:"31",
                設備名称:"CAD/CAM",
                型式:"CATIA-V5システム",
                 仕様最大能力:　"　",
                回転数:"",
                台数:"",
                備考:"",
                 _cellVariants: { NO: 'info'}
            },
        ]
    }),
    methods:{
        search:function() {
            const params = {
                method_name:'YOTEI_HinmokuSD', 
                params:{    
                    strHinmoku: 'B',
                    FLG:'false'
                }
            };
            axios.post('http://localhost/toyo/public/api/soap-call', params)
            .then(response => {
                this.sampleData = response.data.SQL_YOTEI;
                
            })
        }
    }
}
</script>