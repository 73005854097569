<template>
   <v-container class="wrapper mx-n0  " style="background-color:#99CCFF " px-3 py-3 mx-4 my-n3  width="80%"> 
 <v-toolbar-title class="AA">
      ようこそ        伊 藤 鉄 工 株 式 会 社
      </v-toolbar-title>
    <v-card class="wrapper mx-n0  " style="background-color:#99CCFF "  pt-3 pb-0  >
   <!-- <link href="favicon.ico" ref="shortcut icon"> -->
    <!-- <img alt="" class="img1" src="img/favicon.png" width="48px" height="33px">  
    <h3>伊藤鉄工株式会社</h3>    -->
    <v-row class="home-about__contents pa-0 ma-0 pb-0 mb-0 pt-0 mt-1" cols="12">
      <v-col sm="1" class="home-about__contents-img sm justify-content-center pa-0 pb-0 ma-0 mx-0 mt-1 mb-n2">
      <img alt="" class="img1" src="img/favicon.png" width="48px" height="33px">  
       </v-col>
      <v-col cols="3" class="pa-0 ma-0 mx-0 mt-2 mb-0 sm justify-content-left">
         <h4 class="h4">伊藤鉄工株式会社</h4>   
      </v-col>
      <v-col cols="2" class="pa-0 ma-0 mx-0  mt-2 mb-0 sm "></v-col>
   </v-row>
    <slider 
    animation="normal" 
    interval=5000
    speed=500
     width="380px"
    height="250px"
     stopOnHover="true"
    style="align: center margin:0  auto"
    indicators=false
    @change="onPageChange" ma-0 pa-0>
      <slider-item v-for="row in ditems" v-bind:key="row.index" ma-0 pa-0 >
        <div class="flex-fill box" ma-0 pa-0> 
       <img v-bind:src="row.FLG"  class="imgscale "   width="380px" height="250px" ma-0 pa-0 >  
        <!-- </div>
        <div ma-0 pa-0 height="10px"> -->   
          <!-- <img alt="" class="d-flex justify-content-flex-start img3 pa-0 ma-0 mt-0 pt-0 mb-0 pb-0"   src="img/図0.png" width="160px" height="25px">   -->
         <h6 class="d-flex justify-content-start">{{row.詳細1}} </h6>
          
          <h5 class="d-flex align-self-center">{{row.詳細2}} </h5>
         
      
        </div>
      </slider-item>
    </slider>
    </v-card>
   <v-card class="fff">
    <div width="100%">   
       <button class="fb fw">沿 　革</button>
     <b-table stacked :items="sampleData1"  thead-class="d-none"  font-weight:c1bold  class="fg"   :fields ="yfields"  width="380px"  >    
       </b-table>   
    </div>
    <div width="100%">   
       <button class="fb fw">主要取引先</button>
     <b-table stacked :items="sampleData2"  thead-class="d-none"  font-weight:c1bold  class="fg"   :fields ="yfields"  width="380px"  >    
       </b-table>   
    </div>
    </v-card>
   </v-container> 
</template>
<script>
import axios from 'axios'
import { Slider, SliderItem } from 'vue-easy-slider';
var sound_OK;
var sound_NG;
var url_kannami;
export default {
  data () {
    return {
      currentComponent: "",
      moji1:"■１９５６年より航空機部品および治工具の製造に参画。以来、航空機・宇宙産業の発展と共に堅実な歩みを続け、今日に至っております。", 
moji2: "■現在では航空機部品の加工にあたり、３次元CATIAを駆使しプランニング・治工具設計・NCプログラムの検討/作成を実施しています。"
+"部品加工においては、アルミから耐熱合金（チタン・インコネル）、複合材等様々な材質に対し、長年の経験から得た切削データを基に、最適条件での切削を目指した対応を図っています。",
   moji3:"会社名　伊 藤 鉄 工 株 式 会 社 所在地　愛知県津島市藤浪町１－３９<br/>創　立    大正14年1月8日（1925年）<br>設　立    昭和24年1月8日（1949年）資本金　１，０００万円　（授権資本４，０００万円）>従業員　67　名　（令和２年9月1日現在）問合せ先　人事担当　伊藤ＴＥＬ：0567-26-2187　" ,
  moji4:"・１９５６年より航空機部品および治工具の製造に参画。以来、航空機・宇宙産業の発展と共に堅実な歩みを続け、今日に至っております。今後、益々技術が高度化していく、この業界のニーズに応えるべく、この業界の一員たることに誇りをもって<br/>・人材の育成と社員の福祉向上を基本に先端技術の吸収・開発と設備の近代化に<br/>限りなく努力を重ねていく企業です現在では航空機部品の加工にあたり、３次元CATIAを駆使しプランニング・治工具設計・NCプログラムの検討/作成を実施しています。部品加工においては、アルミから耐熱合金（チタン・インコネル）、複合材等様々な材質に対し、長年の経験から得た切削データを基に、最適条件での切削を目指した対応を図っています。",
 moji5:"横型５軸マシニングセンター　　2000×2550×1400 4台<br/> 横型５軸マシニングセンター　　1060×1050×1050 　  	２台  １台",
 dialog: false,
      dialogYes: false,
      dialogYes1: false,
      title:'',
      messge:'',
      messge2:'',
      preIndex: 0,
      params:[],
      xitems:[],
      nfields: [
          {
              key: 'index',
              label: ' ',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '品名CODE',
              label: '品名CODE',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '品名',
              label: '品名',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '売上単価',
              label: '売上単価',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: 'フィールド',
              label: 'フィールド1',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: 'FLG',
              label: 'FLG',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
      ],
      dfields: [
          {
              key: 'index',
              label: ' ',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '品名CODE',
              label: '品名CODE',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '品名',
              label: '品名',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: '売上単価',
              label: '売上単価',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
           {
              key: 'フィールド',
              label: 'フィールド1',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
          {
              key: 'FLG',
              label: 'FLG',
              thClass: 'd-none', tdClass: 'd-none',
              thStyle: {background: '#0074a5', color: '#ffffff'} 
          },
      ],
         sampleData1:[
           {
                項目:"",
                内容:"　　　　　2022 ■ 本社工場の拠点を白浜工場に集約"
            },
           {
                項目:"",
                内容:"　　　　　2018 ■ 白浜第三工場新設　超高速MAG3、5軸FMS新規導入"
            },
             {
                項目:"",
                内容:"　　　　　2007 ■ 国際品質マネジメントシステム (JISQ9100)認証を収得し毎年更新中　"
            },
             {
                項目:"",
                内容:"　　　　　2002 ■ 白浜第二工場新設"
            },

             {
                項目:"",
                内容:"　　　　　1993 ■ これ以降大型、3軸、4軸、5軸 高速機械を導入"
            },
            {
                項目:"",
                内容:"　　　　　1992 ■ 白浜第一工場新設　大型NC機械導入"
            },
            {
                項目:"",
                内容:"　　　　　1990 ■ CAD/CAMシステム導入　CATIA V4、V5、MASTER CAM、VERICUT"
            },
                {
                項目:"",
                内容:"　　　　　1981 ■ 本社第三工場新設　3次元測定器導入"
            },
               {
                項目:"",
                内容:"　　　　　1974 ■ NC工作機械導入"
            },
              {
                項目:"",
                内容:"　　　　　1966 ■ 三菱電機㈱名古屋製作所の協力会社に認定"
            },
              {
                項目:"",
                内容:"　　　　　1956 ■ 新三菱重工業㈱名古屋航空機製作所の協力会社に認定"
            },
               {
                項目:"",
                内容:"　　　　　1955 ■ 新三菱重工業㈱名古屋製作所の協力会社に認定"
            },
             {
                項目:"",
                内容:"　　　　　1949 ■ 法人組織、伊藤鉄工株式会社を設立"
            },
              {
                項目:"",
                内容:"　　　　　1925 ■ 現在地で伊藤鉄工所を創立"
            },
             {
                項目:"",
                内容:"　　　　　　　　　　　　　　　　　　　　　　"              
            }
         ],
            sampleData2:[
            {
                項目:"",
                内容:"　　　　　三菱重工業株式会社"              
            },
             {
                項目:"",
                内容:"　　　　　名古屋航空宇宙システム製作所"              
            },
             {
                項目:"",
                内容:"　　　　　名古屋誘導推進システム製作所"              
            },
             {
                項目:"",
                内容:"　　　　　株式会社 放電精密 加工研究所"              
            },
             {
                項目:"",
                内容:"　　　　　株式会社 神戸製鋼所 大安工場"              
            },
             {
                項目:"",
                内容:"　　　　　ナカノアビエーション株式会社"              
            },
            {
                項目:"",
                内容:"　　　　　関東航空計器株式会社"              
            },
        ],
         yfields: [
        { key: '項目', label: '', thClass: 'd-none', tdClass: 'd-none',},
        { key: '内容', label: '', thStyle: {background: '#0074a5', color: '#ffffff'} ,
          thStyle: {background: '#0074a5', color: '#ffffff'} },
            ],
      nitems:[],
      selectedt:[],
      ditems:[],
    }
  },
  watch:{
    index: function (newData,oldData)
    {
      if(newData != oldData){
      this.dialogYes=true;
      }
    } 
  },
  components: {
    Slider,
    SliderItem,
  },
  created: function() {
    this.$parent.title = '画像１';
    this.$emit('setTitle');
    sound_OK = new Audio("data:audio/wav;base64," + this.$router.options.base64_OK);
    sound_NG = new Audio("data:audio/wav;base64," + this.$router.options.base64_NG);
    url_kannami = this.$router.options.g_kannami;

        this.nitems=[];
        var index;
        var 品名;
        var 品名CODE;
        var 売上単価;
        var フィールド;
        var FLG;
         var 詳細1;
         var 詳細2;
        this.xitems.push([1,'00009',"企業情報",1000,0,"/img/図2.jpg","■輝ける未来のエナジー■","■航空機・宇宙産業の発展と共に堅実な歩みを続けています。■この業界の一員たることに誇りをもって■先端技術の吸収・開発と設備の近代化に■限りなく努力をすすめる企業です。"]);
        this.xitems.push([2,'00010',"製品情報",1000,0,"/img/図1.jpg","■------本社-----拠点■","■■■製造　航空機部品 宇宙産業 高度精密に対応する高速切削技術 NCテープの効率的自動作成 (CATIA) ■■■■治工具設計　品質保障■■■"]);
        this.xitems.push([3,'00012',"工場情報",1000,0,"/img/4門扉.jpg","■------白浜工場----■","■■■新工場 先端技術の拠点 技術力の向上■■■高品質■■■コストダウンにチャレンジし、■■■航空・宇宙産業界に貢献しています。■■■"]);
        this.xitems.push([4,'00012',"工場情報",1000,0,"/img/3外観夕景.jpg","■----白浜工場---■","■■■暁の新工場■■■技術の高度化 新しい動き■■■"]);     
        this.xitems.push([5,'00012',"設備情報",1000,0,"/img/Z004.jpg","■------INTEGREX I-300 ------■",""]);      
         this.xitems.push([6,'00013',"設備情報",1000,0,"/img/6001.jpg","■------MAG-3--------■",""]);
         this.xitems.push([7,'00014',"設備情報",1000,0,"/img/6003.jpg","■------VORTEX1400--------■",""]);
        this.xitems.push([8,'00015',"設備情報",1000,0,"/img/bi (11).jpg","■------MVR30------■",""]);
        this.xitems.push([9,'00016',"設備情報",1000,0,"/img/bi (15).JPG","■------M-H60-5S------■",""]);
         this.xitems.push([10,'00011',"技術情報",1000,0,"/img/Z002.jpg","■------5軸制御　FMS--------■",""]);
        for(var t=0;t<this.xitems.length;t++){
            index = this.xitems[t][0];
             品名 = this.xitems[t][1];
            品名CODE = this.xitems[t][2]; 
             売上単価 = this.xitems[t][3]; 
             フィールド = this.xitems[t][4];

              FLG = this.xitems[t][5]; 
              詳細1=this.xitems[t][6]; 
              詳細2=this.moji2; 
              this.nitems.push({
                index:this.xitems[t][0],
                品名:this.xitems[t][1],
                品名CODE:this.xitems[t][2],
                売上単価:this.xitems[t][3],
                フィールド:this.xitems[t][4],
                FLG:this.xitems[t][5],
                詳細1:this.xitems[t][6],
                詳細2:this.xitems[t][7]
                });
               this.ditems.push({
                index:this.xitems[t][0],
                品名:this.xitems[t][1],
                品名CODE:this.xitems[t][2],
                売上単価:this.xitems[t][3],
                フィールド:this.xitems[t][4],
                FLG:this.xitems[t][5],
                 詳細1:this.xitems[t][6],
                詳細2:this.xitems[t][7]
                });
        }
        this.selectedt = this.nitems;
  },
  methods:{
    
    checkItem() {
        var len;
       this.ditems = [];
        this.selectedt = this.nitems;
        for (var i=0; len=this.selectedt.length; i<len) {
            if (i == len) {break;}
            if(this.selectedt[i].フィールド > 999) {
                this.selectedt[i].FLG = "/img/" + this.selectedt[i].品名CODE + ".jpg"
                this.ditems.push(this.selectedt[i]);
            }
            i++;
        }
    },
    onPageChange: function(index) {
      this.preIndex = index;
    },
    imgclick() {
      if(this.preIndex==0) {
       this.messge =this.moji3 ;
       this.title="会社概要";
         this.dialogYes = true;
        }
      if(this.preIndex==1) {
       this.messge =this.moji4 ;
       this.title="技術情報";
         this.dialogYes = true;
        }  
      
        if(this.preIndex==2) {
       this.messge ="" ;
       this.title="組織情報";
         this.dialogYes1 = true;
        }  
         if(this.preIndex==3) {
       this.messge =this.moji5 ;
       this.title="設備";
         this.dialogYes = true;
        }  
    },
  }
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.slide-item {
  height: 400px;
  width: 100%;
  background-color: #3FB811; 
  display: flex;
  margin:  0 auto; 
  justify-content: start; 
  align-items: start; 
  color: #fff;  
  font-size: 34px; 
  border-radius: 20px;
}
.VueCarousel-slide {
  font-size:12px;
  display: flex;
  align-items: start;
  justify-content: start;
  width:100%;
  height: 400px;
  background:#ccc;
  border-right:1px solid #FFF;
  box-sizing:border-box;
  transform: scale(1.0, 1.0);
}
.imgscale {
  transform: scale(1.0, 1.0);
}

.VueCarousel-navigation-next {
  font-size: 4rem;
}

.VueCarousel-navigation-prev {
  font-size: 4rem;
}

.VueCarousel-navigation--disabled {
  display: none;
}
.large{
  font-size: large;
}
.bg {
 background-image: url("/img/組織図.png");
}
.wrapper{
   display: grid;
  width: 100%;
    max-width: 1720px;
    margin: 0 auto;
    margin-left: 0 auto;
    margin-right: 0  auto;
}
.fg{
  color:black;
}
.fb{
  background-color:black;
}
.fw{
  color:white;
}
.fff{
   background-color:#99CCFF;
}
.box{
  position: relative;
   display: flex;
   max-width: 480px;
    margin-left: auto ;
    margin-right: auto ;
    padding-left: 0px;
    padding-right: 0px;
}
.box h5{
  position: absolute;
   display: flex;
  /* background-color: rgb(14, 81, 204); */
  color:white;
  font-size:x-small;
    top: 20%;
    left: 20%;
    transform: translate(-50%,-50%);
    justify-content:flex-start; 
}

.box h6{
  position: absolute;
   display: flex;
    background-color: rgb(14, 81, 204);  
  color:white;
   font-size:x-small;
    top: 40%;
    left: 35%;
    transform: translate(-50%,-50%);
    justify-content:flex-start; 
}
.h4{
  font-weight: bold;
}
.AA{
  font-weight: bold;
}

</style>
 