<template>
  <v-container>
      <v-card class="pa-4 ma-4" elevation="10">
    <h1 class="text-center">製品情報<h5>伊藤鉄工㈱</h5></h1>
      </v-card>
      <a href="/"><p>戻る</p></a>　
        <transition name="bounce">
  <v-row dense style="backgrond=back" class=" my-n2 mx-n0"  align="center" justify="center">
      
      <b-col md="5" cols="6" class="my-n2 mx-n0" />   
               <b-table  v-if="ok" thead-class="d-none" class="table padding-right: 15em; table-bordered border-primary   border:2px ;border-collapse:collapse;background:#ccc bg-white font-weight:c4bold"   :fields ="yfields"  :items="sampleData" responsive="md"  >                   
                  <template #cell(index)="data" id="hh" >
                      {{ data.index + 1 }}
                  </template>
                  <template #cell()="data">
                    <i>{{ data.value }}</i>
                  </template>
              </b-table>
              
            </v-row>
            
       </transition >      
  </v-container>
</template>
<script>
import axios from 'axios'
export default {
    components: {
    },
    data: () => ({
        ok:true,
        text1:'',
        mail:'',
        length_input:'',
        max_length_input:'',
        list:[],
        sampleData:[
            {
                項目:"製 造",
                内容:"■ 現在では航空機部品の加工にあたり、３次元CATIAを駆使しプランニング・治工具設計・NCプログラムの検討/作成を実施しています。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"技 術",
                内容:"■ 部品加工においては、アルミから耐熱合金（チタン・インコネル）、複合材等様々な材質に対し、長年の経験から得た切削データを基に、最適条件での切削を目指した対応を図っています。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"",
                内容:"",
                 _cellVariants: { 項目: 'info',内容:'info'}         
            },
             {
                 項目:"",
                内容:"",
                 _cellVariants: { 項目: 'info',内容:'info'}    
            },
            {
                項目:"Dream",
                内容:"■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ ■ はるか1万3000メートルの上空を舞う、ボーイング787。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　",
                内容:"全長約60メートル、全高16.5メートルといった巨大な航空機。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:"　",
                内容:"でっかい機体が空を飛ぶためには、エンジンや尾翼といった部位が欠かせません。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:" ",
                内容:"そういった航空機にとって重要なパーツをつくっているのが、弊社なんです。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:" ",
                内容:"日本を代表する航空機メーカー・三菱重工業と50年以上の取引をしている伊藤鉄工。",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:" ",
                内容:"協力会社として、エンジン部品や尾翼やロケット部品といった重要な部品の製造を任されています。",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:" ",
                内容:"≪製品の種類は約4500種類！≫",
                 _cellVariants: { 項目: 'info'}
            },
            {
                項目:" ",
                内容:"航空機のエンジン部品や尾翼部分を中心に約4500種類の部品をつくっています。",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:" ",
                内容:"素材は、鉄・チタン・アルミも、手の平ほどのモノから人間の背丈を超えるようなモノまでさまざまです。",
                 _cellVariants: { 項目: 'info'}
            },
             {
                項目:" ",
                内容:"最近、自動車部品初め民間部品にも更なる挑戦をしております。",
                 _cellVariants: { 項目: 'info'}
            },
        ]
    }),
    methods:{
        search:function() {
            const params = {
                method_name:'YOTEI_HinmokuSD', 
                params:{    
                    strHinmoku: 'B',
                    FLG:'false'
                }
            };
            axios.post('http://localhost/toyo/public/api/soap-call', params)
            .then(response => {
                this.sampleData = response.data.SQL_YOTEI;
               
            })
        }
    }
}
</script>